import React from 'react';
import PropTypes from 'prop-types';

import styles from './MobilePagination.module.css';

import Image from '../../../../elements/Image';

const MobilePagination = ({ currentPage, pagesCount, onChangeCurrentPage }) => (
  <div className={styles.mobilePagination}>
    {
      [...Array(pagesCount).keys()].map((pageNumber, index) => (
        <div
          key={index}
          className={`
            ${styles.mobilePagination__number} 
            ${currentPage === pageNumber + 1 ? `${styles.mobilePagination__numberCurrent}`: ''}
          `}
          onClick={() => onChangeCurrentPage(pageNumber + 1)}
          onKeyDown={() => onChangeCurrentPage(pageNumber + 1)}
          role="button"
          tabIndex={0}
        >
          {pageNumber + 1}
        </div>
      ))
    }
    <div
      className={styles.mobilePagination__nextControl}
      onClick={() => onChangeCurrentPage(currentPage === pagesCount ? 1 : currentPage + 1)}
      onKeyDown={() => onChangeCurrentPage(currentPage === pagesCount ? 1 : currentPage + 1)}
      role="button"
      tabIndex={0}
    >
      <Image name="pagination_chevron_right" />
    </div>
  </div>
)

MobilePagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  onChangeCurrentPage: PropTypes.func.isRequired
}

export default MobilePagination;