import React, { useState } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PodcastsContent.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import MobilePagination from './MobilePagination';
import UnderlinedLink from '../../../elements/UnderlinedLink';

const ITEMS_ON_PAGE = 3;

const PodcastsContent = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/podcasts-content.md/" } }) {
        edges {
          node {
            frontmatter {
              podcasts {
                image
                logoWidth
                date
                title
                description
                href
              }
            }
          }
        }
      }
    }
  `);

  const { podcasts } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const [currentPage, setCurrentPage] = useState(1);

  const renderPodcastItem = (podcast, index) => (
    <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-12 d-flex justify-content-center">
      <div className={styles.podcast}>
        <div className="w-100">
          <div className={styles.podcast__imageContainer}>
            <a href={podcast.href} target="_blank" rel="noopener noreferrer">
              <Image name={podcast.image} className={styles.podcast__image} style={{ width: podcast.logoWidth }} />
            </a>
          </div>
          <div className={styles.podcast__separator} />
          <div className={styles.podcast__date}>{podcast.date}</div>
          <div className={styles.podcast__title} dangerouslySetInnerHTML={{ __html: podcast.title }} />
          <div className={styles.podcast__description}>{podcast.description}</div>
        </div>
        <div>
          <UnderlinedLink href={podcast.href} target="_blank" rel="noopener noreferrer">listen to the podcast</UnderlinedLink>
        </div>
      </div>
    </div>
  );

  const paginate = (_, index) => index >= ITEMS_ON_PAGE * (currentPage - 1) && index < ITEMS_ON_PAGE * (currentPage - 1) + ITEMS_ON_PAGE;

  return (
    <div className={styles.podcastsContent}>
      <Container type="big">
        <div className={styles.desktopView}>
          <div className="row">
            {
              podcasts.map(renderPodcastItem)
            }
          </div>
        </div>
        <div className={styles.mobileView}>
          <div className="row">
            {
              podcasts.filter(paginate).map(renderPodcastItem)
            }
          </div>
          <div className="row">
            <MobilePagination
              currentPage={currentPage}
              pagesCount={Math.ceil(podcasts.length / ITEMS_ON_PAGE)}
              onChangeCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </Container>
      <Image name="podcasts_plant" className={styles.podcastsContent__image} />
    </div>
  )
}

export default PodcastsContent;