import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PodcastsHeader.module.css';

import Container from '../../../blocks/Container';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Image from '../../../elements/Image';

const PodcastsHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/podcasts-header.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
            }
          }
        }
      }
    }
  `);

  const { title, subtitle } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.podcastsHeader}>
      <Container>
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-12 pl-xl-1 pl-lg-1 pr-xl-0 pr-lg-0">
              <Title type="constant" className="white-text text-left p-xl-0 p-lg-0 p-md-2 p-0 mb-4">
                {title}
              </Title>
              <Text className="white-text text-left p-xl-0 p-lg-0 p-md-2 p-0 mb-xl-5 mb-lg-5 mb-4">
                {subtitle}
              </Text>
            </div>
            <div className="col-xl-7 col-lg-7 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center">
              <Image name="padcasts_header_image" className={styles.podcastsHeader__image} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )
}

export default PodcastsHeader;