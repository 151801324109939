import React from 'react';
import PropTypes from 'prop-types';

import styles from './UnderlinedLink.module.css';

const UnderlinedLink = ({ href, target, children }) => (
  <a href={href} target={target} className={styles.underlinedLink}>{children}</a>
)

UnderlinedLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  target: PropTypes.string
}

UnderlinedLink.defaultProps = {
  target: '_self'
}

export default UnderlinedLink;