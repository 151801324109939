import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import PodcastsHeader from '../components/slides/podcasts/PodcastsHeader';
import PodcastsContent from '../components/slides/podcasts/PodcastsContent';

const Podcasts = () => {
  return (
    <Layout>
      <SEO
        title="Podcasts | Deadline Funnel"
        //TODO add valid description
        description="Course creators, marketers, and business owners are using Deadline Funnel every day to grow their impact through real evergreen marketing. Are you next?"
      />
      <PodcastsHeader />
      <PodcastsContent />
    </Layout>
  )
};

export default Podcasts;